import * as yup from 'yup';

export const schema = yup.object().shape({
	username: yup
		.string()
		.email('Please, enter a valid email address')
		.required('Email is a required field'),
	password: yup
		.string()
		.min(8, 'Password must be at least 8 characters')
		.required('Password is a required field'),
});
