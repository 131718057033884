import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';
import styledMap from 'styled-map';

import { Separator } from '@fllite-fe/shared/src/components/Separator';

const TabsContainer = styled('div')`
	display: flex;
	justify-content: space-around;
`;

interface TabProps {
	active?: boolean;
}

const tabColor = styledMap`
  default: ${({ theme }) => theme.colors.grayDarker};
  active: ${({ theme }) => theme.colors.primary};
  `;

const tabFontWeight = styledMap`
  default: 400;
  active: 600;
`;

export const Tab = styled('button')<TabProps>`
	font-size: 14px;
	font-weight: ${tabFontWeight};
	color: ${tabColor};
	transition: color ${({ theme }) => theme.transition.baseTransition};
	padding: 14px 15px;
	background: #ffffff;
	border: none;
	outline: none;
	cursor: pointer;
	text-decoration: none;

	${({ active, theme }) => active && `border-bottom: 3px solid ${theme.colors.primary};`}

	&:hover {
		color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.primaryDark)};
	}
`;

const Container = styled('div')``;

const TabContainer = styled('div')`
	margin: 0 20px;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 0 200px;
	}
`;

interface TabsProps {
	children: ReactNode;
}

export const Tabs: FC<TabsProps> = ({ children }) => (
	<Container>
		<TabContainer>
			<TabsContainer>{children}</TabsContainer>
		</TabContainer>
		<Separator />
	</Container>
);
