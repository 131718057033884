import styled from '@emotion/styled';
import styledMap from 'styled-map';

interface SeparatorProps {
	dashed?: boolean;
	vertical?: boolean;
}

const bg = styledMap`
  default: ${({ theme }) => theme.colors.grayLight};
	dashed: repeating-linear-gradient(
			45deg,
			${({ theme }) => theme.colors.grayLight},
        ${({ theme }) => theme.colors.grayLight} 4px,
			transparent 4px,
			transparent 10px
		);
`;

const height = styledMap`
	default: 1px;
	vertical: 100%
`;

const width = styledMap`
	default: 100%;
	vertical: 1px
`;

export const Separator = styled('div', {
	shouldForwardProp: (prop) => prop !== 'dashed' && prop !== 'vertical',
})<SeparatorProps>`
	height: ${height};
	width: ${width};
	background: ${bg};
`;
