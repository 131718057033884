import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tab, Tabs } from 'components/PageAuthCommon/Tabs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormContainer as RHFForm, TextFieldElement, useForm } from 'react-hook-form-mui';

import { useAuthentication } from '@fllite-fe/shared';
import { Button } from '@fllite-fe/shared/src/components/Button';
import { Text } from '@fllite-fe/shared/src/components/Text';
import PasswordElement from '@fllite-fe/shared/src/mui-form-fields/PasswordElement';

import { schema } from './validationSchema';

const Container = styled('div')``;

const FormContainer = styled('div')`
	padding: 30px 0;

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 30px 40px;
	}
`;

const BottomSection = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.5rem;
`;

const SubmitButton = styled(Button)`
	min-width: 293px;
	display: block;
	margin-bottom: 25px;
	position: relative;
`;

const Row = styled('div')`
	position: relative;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 2rem;
		display: flex;
		align-items: flex-start;
	}
`;

const Col = styled('div')`
	margin-top: 25px;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 0;
		width: 100%;
		padding: 0 15px;
	}
`;

const TitleInputContainer = styled('div')`
	margin: 1rem 0 0 1rem;
	font-weight: 500;
	font-size: 12px;
	line-height: 160%;
`;

const InputContainer = styled('div')`
	margin-bottom: 20px;
`;

const Image = styled('img')``;

const LogoContainer = styled('div')`
	margin: 0 0 40px;
	text-align: center;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 40px 0;
	}
`;

const LinkContainer = styled('div')`
	display: flex;
	flex-direction: column;
	align-content: space-around;
	align-items: center;
`;

const LinkText = styled(Text)`
	color: ${({ theme }) => theme.colors.primary};
	transition: color ${({ theme }) => theme.transition.baseTransition};
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;
	margin-top: 12px;
	text-decoration: none;

	&:hover {
		color: ${({ theme }) => theme.colors.primaryDark};
	}
`;

type FormValues = {
	username: string;
	password: string;
};

export const Form = () => {
	const { pathname } = useRouter();
	const { signIn, isLoadingSignIn } = useAuthentication();

	const handleFormSubmit = (values: FormValues) => {
		// @TODO: Validate that I have charterOperator on user object
		signIn(values);
	};

	const formContext = useForm({
		resolver: yupResolver(schema),
	});

	return (
		<Container>
			<LogoContainer>
				<Image src="/assets/logo_fllite_ops.svg" alt="logo fllite operations" />
			</LogoContainer>
			<Tabs>
				<Link passHref href="/register" legacyBehavior>
					<Tab as="a" active={pathname === '/register'}>
						Sign up
					</Tab>
				</Link>
				<Link passHref href="/login" legacyBehavior>
					<Tab as="a" active={pathname === '/login'}>
						Log in
					</Tab>
				</Link>
			</Tabs>
			<FormContainer>
				<RHFForm onSuccess={handleFormSubmit} formContext={formContext}>
					<Row>
						<Col>
							<TitleInputContainer>Email address</TitleInputContainer>
							<InputContainer>
								<TextFieldElement
									name="username"
									placeholder="Email"
									variant="standard"
									fullWidth
								/>
							</InputContainer>
						</Col>
					</Row>

					<Row>
						<Col>
							<TitleInputContainer>Password</TitleInputContainer>
							<InputContainer>
								<PasswordElement
									name="password"
									placeholder="Password"
									variant="standard"
									fullWidth
									isStartAdornment={false}
									iconColor="primary"
								/>
							</InputContainer>
						</Col>
					</Row>
					<BottomSection>
						<SubmitButton isLoading={isLoadingSignIn} secondary type="submit">
							Log in
						</SubmitButton>
					</BottomSection>
					<LinkContainer>
						<Link href="/forgotten-password" passHref legacyBehavior>
							<LinkText as="a" small>
								Forgot your password?
							</LinkText>
						</Link>
						<Link href="/contact-us" passHref legacyBehavior>
							<LinkText as="a" small>
								Contact us
							</LinkText>
						</Link>
					</LinkContainer>
				</RHFForm>
			</FormContainer>
		</Container>
	);
};
