import { Layout } from 'components/PageAuthCommon/Layout';
import { Form } from 'components/PageLogin/Form';

const PageLogin = () => (
	<Layout>
		<Form />
	</Layout>
);

export default PageLogin;
